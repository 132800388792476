.App {
  text-align: center;
}

.calculator-container {
  padding: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
}

 .calculator-splitContainer {
  display: flex;
}

.calculator-splitContainer .calculator-inputSection {
  width: 50%;
}

.calculator-chart {
  align-self: center;
  width: 90%;
  height: 80vh;
}

.calculator-inputSection {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.calculator-reset {
  float: right;
}

.recharts-surface {
  overflow: visible;
}

.text-row {
  display: flex;
  white-space: pre;
}

.text {
  margin: 1rem;
}

.disclaimer {
  white-space: break-spaces;
}
